import React, { useState } from 'react';
import './tech.scss';
import classnames from 'classnames';
function Technique(props) {
  const {active,setActive}=props;
  return (
      <div className="x-tech-container">
    <div className="container-fluid d-flex justify-content-center">
     <div
       className='headng'
      >
     Technologies we use
      </div>
      <div className='row t-row'>
        <div className={classnames("catagories",{active:active===0})} onClick={()=>setActive(0)}>Mobile</div>
        <div className={classnames("catagories",{active:active===1})} onClick={()=>setActive(1)}>Front End</div>
        <div className={classnames("catagories",{active:active===2})} onClick={()=>setActive(2)}>Backend</div>
        <div className={classnames("catagories",{active:active===3})} onClick={()=>setActive(3)}>Cms</div>
        <div className={classnames("catagories",{active:active===4})} onClick={()=>setActive(4)}>Graphic Design</div>
        <div className={classnames("catagories",{active:active===5})} onClick={()=>setActive(5)}>Digital Marketing</div>
      </div>
      </div>
      <hr style={{width:'80%',marginTop:'auto'}} className="x-hr"/>

      </div>

  );
}
export default Technique;
