import React, { useState, useEffect } from "react";
import "./clientspeak.scss";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import CompletedWorksBlockCustom2 from "./jindex2";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

var mouseOver = false;
var scrollPosition = 0;

const itemSet = [
  {
    src: require("../../../assets/testimonial/maryose-maropakis.png").default,
    altText: `I found a2zwebsolution team corporative and shown excellent results in the timeline given.`,
    caption: "Maryrose Maropakis",
    caption2: "Executive Director",
    caption3: "Tobblin llc",
    color: "light",
  },
  {
    src: require("../../../assets/testimonial/baozhai.png").default,
    altText: `Our experience with a2zwebsolution has been positive. They were able to develop all of the requirements as needed`,
    caption: "Baozhai",
    caption2: "Ui/Ux designer",
    caption3: "Fen-Tech",
    color: "dark",
  },
  {
    src: require("../../../assets/testimonial/abayomi-oyetunde.png").default,
    altText: `A professional company in web design & development, excellent support team during & after the project closure.`,
    caption: "Abayomi Oyetunde",
    caption2: "Ceo",
    caption3: "Ejete Consultants",
    color: "light",
  },
  {
    src: require("../../../assets/testimonial/aishwarya.png").default,
    altText: `The team was very easy to communicate with and they were very flexible to my time schedule. Design process was very smooth and everything was done swiftly.`,
    caption: "Aishwarya",
    caption2: "Lawyer",
    caption3: "",
    color: "dark",
  },
  {
    src: require("../../../assets/testimonial/tejas-yash.png").default,
    altText: `Amazing Tech work and great to work with! Very attentive and know what they are doing.`,
    caption: "Tejas Yash",
    caption2: "Software engineer",
    caption3: "Elementt llc",
    color: "light",
  },
  {
    src: require("../../../assets/testimonial/lucas-peter.png").default,
    altText: `It took a while to find a company like A2zwebsolution that could accomplish what we needed for our new website, and I have to say it was well worth the wait.`,
    caption: "Lucas Peter",
    caption2: "President",
    caption3: "Google.com",
    color: "dark",
  },
  {
    src: require("../../../assets/testimonial/liam-john.png").default,
    altText: `Outstanding Web Design Agency, very efficient and professional. I highly recommend them.`,
    caption: "Liam John",
    caption2: "Ceo",
    caption3: "Unity Bank plc",
    color: "light",
  },
  {
    src: require("../../../assets/testimonial/aiden-oliver.png").default,
    altText: `As a startup company, with their SEO prowess they optimized our company’s website to help drive traffic by improving keyword rankings in search engines.`,
    caption: "Aiden Oliver",
    caption2: "Director General",
    caption3: "Odin stock",
    color: "dark",
  },
  {
    src: require("../../../assets/testimonial/sonia-ruth.png").default,
    altText: `Appreciated the great energy of the young team and their high level of professionalism
    level of professionalism.`,
    caption: "Sonia Ruth",
    caption2: "President",
    caption3: "ZRM international",
    color: "light",
  },
  {
    src: require("../../../assets/testimonial/inesh-moses.png").default,
    altText: `I needed to have a strong brand and website for my startup company to reflect my scope of services. They Helped me build a great brand that stood out in the competitive landscape.`,
    caption: "Inesh Moses",
    caption2: "Senior Product manager",
    caption3: "",
    color: "dark",
  },
];

export default function CompletedWorksBlockCustom(props) {
  const [active, setActive] = useState(itemSet.length - 1);
  const [animating, setAnimating] = useState(false);
  const [direction, setDirection] = useState("ltr");
  const isMobile = useMediaQuery({ query: "(max-width: 767.88px)" });

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? "up" : "down";
    }
    console.log("Direction ", direction);
    console.log("onMouseOver ", mouseOver);

    if (mouseOver && direction === "up") {
      onPrev();
      e.preventDefault();
      return false;
    } else if (mouseOver && direction === "down") {
      onNext();
      e.preventDefault();
      return false;
    }
  }

  const detectMouseWheelDirectionThrottled = _.throttle(
    detectMouseWheelDirection,
    2000
  );
  const onMouseWheel = (e) => {
    if (mouseOver) {
      detectMouseWheelDirectionThrottled(e);
      e.preventDefault();
      return false;
    }
  };

  const history = useHistory();

  const getItems = function () {
    var items = [];
    var activeItem = active;

    while (itemSet.length > 0) {
      if (items.length < itemSet.length && itemSet.length > 0) {
        items.push(itemSet[activeItem]);
        if (activeItem < itemSet.length - 1) {
          activeItem = activeItem + 1;
        } else {
          activeItem = 0;
        }
      } else {
        break;
      }
    }
    return items;
  };

  function onNext() {
    if (animating) return;
    setDirection("rtl");
    setAnimating(true);
    console.log(":::current Active ", active);
    if (active >= itemSet.length - 1) {
      console.log(":::Next Active ", 0);
      setActive(0);
    } else {
      console.log(":::Next Active ", active + 1);
      setActive((prev) => {
        return prev + 1;
      });
    }
    setTimeout(() => {
      setAnimating(false);
    }, 4000);
    setDirection("rtl");
  }

  useEffect(() => {
    if (!animating) {
      onNext();
    }
  }, [animating]);

  const onPrev = () => {
    if (animating) return;
    setAnimating(true);
    if (active === 0) {
      setActive(itemSet.length - 1);
    } else {
      setActive(active - 1);
    }
    setTimeout(() => {
      setAnimating(false);
    }, 4000);
    setDirection("ltr");
  };

  function getImageContainer(itm, index) {
    return (
      <div className="x-image-container">
        <div className="r1">
          <div className="cmnt">
            {itm.altText}
          </div>
          <div className="row x-person-container">
            <div style={{ marginTop: "0.5555vw" }}>
              <img src={itm.src} className="clientimg" />
            </div>
            <div style={{ marginLeft: "0.6rem" }}>
              <div className="cname">{itm.caption}</div>
              <div className="cposition">{itm.caption2}</div>
              {itm.caption3 && (
                <div className="ccompany"> - {itm.caption3}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    //window.addEventListener("wheel", onMouseWheel, { passive:false })
    //let inter = setInterval(onNext, 4030);
    //return () => clearInterval(inter);
    //onNext();
  }, []);

  return (
    <div className="x-clientspeak-container">
      <div style={{ backgroundColor: "#F3F3F3", padding: "0.0694vw" }}>
        <div className="x-block">
          <div
            className="container-fluid px-0"
            style={{
              marginTop: "8.611vw",
              marginBottom: "8.611vw",
            }}
          >
            <div className="row x-speak-container">
              <div
                style={{
                  marginTop: "18.2638vw",
                  width: "23.680vw",
                  marginRight: "6.180vw",
                  marginLeft: "9.861vw",
                }}
                className="x-content"
              >
                <div className="head1">100% of our clients</div>
                <div className="f1">Cant be Wrong</div>
                <div className="f2">
                  We may be tech experts, but our process takes far more than
                  technology into account.{" "}
                </div>
              </div>

              <div
                className="x-carousel"
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-delay="400"
                id="v-slider"
              >
                <div
                  className="x-carousel-item-container left"
                  onMouseEnter={() => (mouseOver = true)}
                  onMouseLeave={() => (mouseOver = false)}
                >
                  {getItems().map((item, idx) => (
                    <div
                      className={classnames(
                        "x-carousel-item animate__animated ",
                        {
                          animate__slideInUp: animating && direction === "rtl",
                          animate__slideInDown:
                            animating && direction === "ltr",
                        },
                        { dark: isMobile && item.color === "dark" }
                      )}
                      key={idx + item.caption}
                    >
                      {getImageContainer(item, idx)}
                    </div>
                  ))}
                </div>
                <div className="x-bottom-gradient" />
                <div className="x-top-gradient" />
              </div>
              {!isMobile && (
                <div className="x-right-reel">
                  <CompletedWorksBlockCustom2 />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
