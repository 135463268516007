import LazyLoad from "react-lazyload";
import Config from "../../config";
import React, { useState, useEffect } from "react";

var isSame = false;
function Image({ lazy, lazyPrefix, src, alt, external, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [prevSrc, setPrevSrc] = useState("");
  const srcUrl = external ? src : Config.assetUrl + src;
  const Img = () => (
    <img
      {...props}
      src={srcUrl}
      alt={alt || ""}
      onLoad={() => setLoaded(true)}
      style={{ visibility: loaded ? "visible" : "hidden" }}
    />
  );

  useEffect(() => {
    if (src === prevSrc) {
      isSame = true;
    } else {
      isSame = false;
      setPrevSrc(src);
    }
  }, [src]);

  return lazy ? (
    <LazyLoad
      classNamePrefix={
        lazy && typeof lazyPrefix === "string" ? lazyPrefix : undefined
      }
    >
      <Img />
    </LazyLoad>
  ) : (
    <Img />
  );
}

export default React.memo(Image, () => isSame);
