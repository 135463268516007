import React, { Fragment, useState } from "react";
import { Carousel, CarouselItem, CarouselCaption } from "reactstrap";
import Technique from "../../pages/home/tech";
import "./techcarousel.scss";
import classnames from "classnames";

const items = [
  {
    src: require("../../../assets/icons/ios.svg").default,
    src2: require("../../../assets/icons/android.svg").default,
    src3: require("../../../assets/icons/react.svg").default,
    src4: require("../../../assets/icons/flutter.svg").default,
    src5: require("../../../assets/icons/ionic.svg").default,
    src6: require("../../../assets/icons/swift.svg").default,
    src7: require("../../../assets/icons/korlin.svg").default,
    src8: require("../../../assets/icons/objc.svg").default,
    src9: require("../../../assets/icons/titanium.svg").default,
    src10: require("../../../assets/icons/xamarin.svg").default,
    text1: "IOS",
    text2: "Android",
    text3: "React",
    text4: "Flutter",
    text5: "Ionic",
    text6: "Swift",
    text7: "Korlin",
    text8: "Objective C",
    text9: "Titanium",
    text0: "Xamarin",
  },
  {
    src: require("../../../assets/icons/icons/front end/cib_vue-js.svg")
      .default,
    src2: require("../../../assets/icons/icons/front end/bi_bootstrap-fill.svg")
      .default,
    src3: require("../../../assets/icons/icons/front end/ant-design_html5-filled.svg")
      .default,
    src4: require("../../../assets/icons/icons/front end/ic-technology-ionic2x 1.svg")
      .default,
    src5: require("../../../assets/icons/icons/front end/akar-icons_github-fill.svg")
      .default,
    src6: require("../../../assets/icons/icons/front end/file-icons_codekit.svg")
      .default,
    src7: require("../../../assets/icons/icons/front end/simple-icons_react.svg")
      .default,
    src8: require("../../../assets/icons/icons/front end/akar-icons_angular-fill.svg")
      .default,
    src9: require("../../../assets/icons/icons/front end/bx_bxl-flutter.svg")
      .default,
    text1: "Vue Js",
    text2: "Bootstrap",
    text3: "HTML5",
    text4: "Ionic",
    text5: "GitHub",
    text6: "CodeKit",
    text7: "React",
    text8: "Angular",
    text9: "Flutter",
  },
  {
    src: require("../../../assets/icons/icons/backend/akar-icons_javascript-fill.svg")
      .default,
    src2: require("../../../assets/icons/icons/backend/akar-icons_python-fill.svg")
      .default,
    src3: require("../../../assets/icons/icons/backend/codicon_ruby.svg")
      .default,
    src4: require("../../../assets/icons/icons/backend/file-icons_php.svg")
      .default,
    src5: require("../../../assets/icons/icons/backend/cib_java.svg").default,
    src6: require("../../../assets/icons/icons/backend/bx_bxl-c-plus-plus.svg")
      .default,
    src8: require("../../../assets/icons/icons/backend/cib_scala.svg").default,
    src9: require("../../../assets/icons/icons/backend/cib_kotlin.svg").default,
    text1: "JavaScript",
    text2: "Python",
    text3: "Ruby",
    text4: "PHP",
    text5: "GitHub",
    text6: "C++",
    text8: "Kotlin",
    text9: "Angular",
  },
  {
    src2: require("../../../assets/icons/icons/cms/bx_bxl-magento.svg").default,
    src3: require("../../../assets/icons/icons/cms/bi_wordpress.svg").default,
    src4: require("../../../assets/icons/icons/cms/jam_drupal-circle.svg")
      .default,
    src5: require("../../../assets/icons/icons/cms/simple-icons_woo.svg")
      .default,
    src6: require("../../../assets/icons/icons/cms/fa-brands_opencart.svg")
      .default,
    text2: "Magento",
    text3: "Wordpress",
    text4: "Drupal",
    text5: "WooCommerce",
    text6: "OpenCart",
  },
  {
    src2: require("../../../assets/icons/icons/graphic design/simple-icons_adobephotoshop.svg")
      .default,
    src3: require("../../../assets/icons/icons/graphic design/simple-icons_adobeindesign.svg")
      .default,
    src4: require("../../../assets/icons/icons/graphic design/file-icons_coreldraw-alt.svg")
      .default,
    src5: require("../../../assets/icons/icons/graphic design/ant-design_sketch-outlined.svg")
      .default,
    src6: require("../../../assets/icons/icons/graphic design/cib_adobe-illustrator.svg")
      .default,
    text2: "Photoshop",
    text3: "InDesign",
    text4: "Corel Draw",
    text5: "Sketch",
    text6: "Adobe Illustrator",
  },
  {
    src2: require("../../../assets/icons/Google.svg").default,
    src3: require("../../../assets/icons/Keyword.svg").default,
    src4: require("../../../assets/icons/Sitemap.svg").default,
    src5: require("../../../assets/icons/Webmaster.svg").default,
    text2: "Google Analytic",
    text3: "Keyword planner",
    text4: "Sitemap Generator",
    text5: "Webmaster Console",
  },
];

const Techcarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(2 || nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div className="home-tech">
          <div
            className={classnames("t-cont", {
              digitialMarketing: activeIndex === 5,
            })}
          >
            <div className="row d-flex t-cont2">
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src,
                })}
              >
                <img className="t-img" src={items[activeIndex].src} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text1}
                </div>
              </div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src2,
                })}
              >
                <img className="t-img" src={items[activeIndex].src2} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text2}
                </div>
              </div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src3,
                })}
              >
                <img className="t-img" src={items[activeIndex].src3} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text3}
                </div>
              </div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src4,
                })}
              >
                <img className="t-img" src={items[activeIndex].src4} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text4}
                </div>
              </div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src5,
                })}
              >
                <img className="t-img" src={items[activeIndex].src5} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text5}
                </div>
              </div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src6,
                })}
              >
                <img className="t-img" src={items[activeIndex].src6} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text6}
                </div>
              </div>
            </div>
            <div
              className={classnames("row icon-hidden justify-content-between", {
                backend: activeIndex === 2,
              })}
              style={{ width: "90%", marginLeft: "4.5138vw" }}
            >
              <div></div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src7,
                })}
              >
                <img className="t-img" src={items[activeIndex].src7} />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text7}
                </div>
              </div>
              {items[activeIndex].src8 && (
                <div
                  className={classnames("icon-box t-cont-img", {
                    active: items[activeIndex].src8,
                  })}
                >
                  <img
                    className="t-img t-img-left"
                    src={items[activeIndex].src8}
                  />
                  <div className="t-img-bottom d-flex justify-content-center">
                    {items[activeIndex].text8}
                  </div>
                </div>
              )}
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src9,
                })}
              >
                <img
                  className="t-img  t-img-left2"
                  src={items[activeIndex].src9}
                />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text9}
                </div>
              </div>
              <div
                className={classnames("icon-box t-cont-img", {
                  active: items[activeIndex].src10,
                })}
              >
                <img
                  className="t-img  t-img-left2"
                  src={items[activeIndex].src10}
                />
                <div className="t-img-bottom d-flex justify-content-center">
                  {items[activeIndex].text0}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Fragment>
      <div style={{ backgroundColor: "#F3F3F3" }}>
        <Technique active={activeIndex} setActive={setActiveIndex} />
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={3000}
        >
          {slides}
        </Carousel>
      </div>
    </Fragment>
  );
};

export default Techcarousel;
