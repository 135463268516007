import React, { Suspense, lazy, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/quill.bubble.css";
import "./css/quill.core.css";
import "./css/quill.snow.css";
import App from "./App";
import "animate.css/animate.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import history from "./history";
import "./fonts/dm-reg/fonts.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactLoading from "react-loading";
import SiteSeo from "./components/site-seo";
import AutoScrollToTop from "./components/scroll-to-top";
import MainPopup from "../src/components/main-popup";

const AboutIndex = lazy(() => import("../src/components/pages/about/index"));
const ServicesIndex = lazy(() =>
  import("../src/components/pages/services/index")
);
const Portfolio = lazy(() =>
  import("../src/components/pages/our-portfolio/index")
);
const ServicesWeb = lazy(() =>
  import("../src/components/pages/services/services-web/index")
);
const ServicesTesting = lazy(() =>
  import("../src/components/pages/services/service-testing/index")
);
const ServicesBrand = lazy(() =>
  import("../src/components/pages/services/service-brand/index")
);
const ServicesMobile = lazy(() =>
  import("../src/components/pages/services/service-mobile/index")
);
const ServicesDM = lazy(() =>
  import("../src/components/pages/services/sevices-dm/index")
);
const ContactUs = lazy(() => import("../src/components/pages/contact/index"));
const PricingIndex = lazy(() =>
  import("../src/components/pages/pricing/index")
);
const PricingIndex2 = lazy(() =>
  import("../src/components/pages/casestudy/index")
);
const Restaurant = lazy(() =>
  import("../src/components/pages/casestudy/restaurant/index")
);
const TeachersDashboard = lazy(() =>
  import("../src/components/pages/casestudy/teachers-dashboard/index")
);
const PricingIndex3 = lazy(() =>
  import("../src/components/pages/casestudy/2/index")
);
const Blogs = lazy(() => import("../src/components/pages/blogs"));
const BlogDetails = lazy(() => import("../src/components/pages/blog-details"));

function RootApp() {
  const [popup, setPopup] = useState(false);

  function showPopup() {
    setPopup(true);
  }

  function onClosePopup() {
    setPopup(false);
  }

  useEffect(() => {
    setTimeout(() => showPopup(), 3000);
  }, []);

  return (
    <Router history={history}>
      {/*<MainPopup
        openState={[popup, setPopup]}
        onClose={onClosePopup}
        path={"/contact"}
      />*/}
      <SiteSeo />
      <AutoScrollToTop />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20vw",
            }}
          >
            {" "}
            <ReactLoading
              type="spinningBubbles"
              color="black"
              height={"10%"}
              width={"10%"}
            />
          </div>
        }
      >
        <Switch>
          <Route path="/blogs/:slug" component={BlogDetails} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/about" component={AboutIndex} />
          <Route path="/services/web-development" component={ServicesWeb} />
          <Route path="/services/app-development" component={ServicesMobile} />
          <Route path="/services/testing-qa" component={ServicesTesting} />
          <Route
            path="/services/branding-designing"
            component={ServicesBrand}
          />
          <Route path="/services/digital-marketing" component={ServicesDM} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/pricing" component={PricingIndex} />
          <Route path="/casestudies/health-app" component={PricingIndex2} />
          <Route
            path="/casestudies/ecommerce-website"
            component={PricingIndex3}
          />
          <Route path="/casestudies/restaurant-app" component={Restaurant} />
          <Route
            path="/casestudies/teachers-dashboard"
            component={TeachersDashboard}
          />
          <Route path="/services" component={ServicesIndex} />
          <Route path="/casestudies" component={Portfolio} />
          <Route path="/" component={App} />
        </Switch>
      </Suspense>
    </Router>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
