import { Button } from "reactstrap";
import React,{useState,useEffect} from "react";
import "./news.scss";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";
import getBlogs from "../../../commands/getBlogs";
import getSlug from "../../../commands/core/getSlug";

function News() {
  let history = useHistory();
  const [data, setData] = useState([]);

  useEffect(() => {
    getBlogs({ page: 1, perPage: 3 }).then((data) => {
      setData(data.results);
    });
  }, []);

  function handleClick() {
    history.push("/pricing");
  }

  if (!(data.length > 0)) {
    return null;
  }

  return (
    <div className="x-news-container">
      <div
        className="d-flex justify-content-center"
        style={{ flexDirection: "column" }}
      >
        <div className="nhd">News that helps</div>
        <div className="ntxt">
          With lots of unique blocks, you can easily build a page without
          coding. Build your next landing page.
        </div>
      </div>
      <div className="row newcnt">
        {data.map((item,index)=>(
          <div class="card ncard" onClick={()=>history.push("/blogs" + `/${getSlug(item.title)}-${item.row_id}`)} >
            <LazyLoad>
              <img
                class="nimg portimg"
                src={item.thumbnail_url}
                alt="Card image cap"
              />
            </LazyLoad>
            <div class="card-body">
              <h4 class="card-title nhead">{item.category}</h4>
              <p class="card-text ntext">
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/*<div className='row newcnt'>
      <div class="card ncard">
        <LazyLoad>
            <img
              class="nimg portimg"
              src={require("../../../assets/n1.png").default}
              alt="Card image cap"
            />
            </LazyLoad>
            <div class="card-body">
              <h4 class="card-title nhead">Career</h4>
              <p class="card-text ntext">How to win any job you want. Get started with 5 steps.</p>
            </div>
          </div>

          <div class="card ncard" >
            <LazyLoad>
            <img
              class="nimg portimg"
              src={require("../../../assets/n2.png").default}
              alt="Card image cap"
            />
            </LazyLoad>
            <div class="card-body">
              <h4 class="card-title nhead">Lifestyle</h4>
              <p class="card-text ntext">10 ways to reduce your office work depression.</p>
            </div>
          </div>

          <div class="card ncard card-hidden">
            <LazyLoad>
            <img
              class="nimg portimg"
              src={require("../../../assets/n3.png").default}
              alt="Card image cap"
            />
            </LazyLoad>
            <div class="card-body">
              <h4 class="card-title nhead">Career</h4>
              <p class="card-text ntext">Why should you work as a team even on small projects.</p>
            </div>
          </div>
  </div> */}
      <div className="row nscont">
        <div className="news-start">
          <div className="nshd">Ready to launch your next project?</div>
          <div className="nstxt">
            We deliver well-suited solution to sure your business sustainability
            and high performance.
          </div>
        </div>
        <div>
          <Button className="nbttn" onClick={handleClick}>
            Get started a project
          </Button>
        </div>
      </div>
      {/* <div
        className="row d-flex justify-content-center"
      >
        <div> 
          <img className='news-img img-wid1' src={require("../../../assets/pic1.png").default} />
        </div>
        <div>
          <img className='news-img img-wid1' src={require("../../../assets/pic2.png").default} />
        </div>
        <div>
          <img className='news-img news-mar img-wid2' src={require("../../../assets/pic3.png").default} />
        </div>
        <div>
          <img className='img-wid2' src={require("../../../assets/pic4.png").default} />
        </div>
      </div>
      <div
        className="row d-flex justify-content-center"
      >
        <div > 
          <img className='news-img img-wid2' src={require("../../../assets/icon (2).png").default} />
        </div>
        <div>
          <img className='news-img img-wid3' src={require("../../../assets/icon (1).png").default} />
        </div>
        <div>
          <img className='news-img news-mar img-wid4' src={require("../../../assets/icon (3).png").default} />
        </div>
        <div>
          <img className='img-wid5' src={require("../../../assets/icon (4).png").default} />
        </div>
      </div> */}
    </div>
  );
}

export default News;
