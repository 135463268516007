import React, { useState, useEffect } from "react";
import classnames from "classnames";
import "./about.scss";
import LazyLoad from "react-lazyload";
import Config from "../../../config";

function About() {
  const [active, setActive] = useState(0);
  const [prev, setPrev] = useState(-1);
  function setActiveImage(newActive) {
    setPrev(active);
    setActive(newActive);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prev) => {
        setPrev(prev);
        return prev + 1 >= 3 ? 0 : prev + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="x-about-section-block d-flex justify-content-center">
      <div class="text-black headng2">
        We are a <span style={{ color: "#FF921C" }}>digital company</span>{" "}
        focused on the web development and mobile apps. We offer entire
        solutions and full long-term technical support in these areas.
      </div>
      <div className="row a-cont">
        <div className="x-items-wrapper">
          <div
            className={classnames("abcnt", { active: active === 0 })}
            onClick={() => setActiveImage(0)}
          >
            <h3 class="head">Web design & Development</h3>
            <h5 className={classnames("para", { active: active === 0 })}>
              We deliver detailed front and backend development that makes your
              idea shine on its own.
            </h5>
          </div>
          <div
            className={classnames("abcnt", { active: active === 1 })}
            onClick={() => setActiveImage(1)}
          >
            <h3 class="text-black head">Branding & design</h3>
            <h5 className={classnames("para", { active: active === 1 })}>
              We help you craft from zero all your ideas into a digital project
              identifying all your needs.
            </h5>
          </div>
          <div
            className={classnames("abcnt2", { active: active === 2 })}
            onClick={() => setActiveImage(2)}
          >
            <h3 class="text-black head">Application</h3>
            <h5 className={classnames("para", { active: active === 2 })}>
              We discreetly craft user experiences of iOS & Android
              applications. We are experts at making apps user friendly,
              beautiful and engaging.
            </h5>
          </div>
        </div>
        <div className="a-img-cont img-hidden">
          <LazyLoad classNamePrefix="abcimg">
            <img
              className={classnames("abcimg animate__animated", {
                active: active === 0 || prev === 0,
                animate__fadeIn: active === 0,
                animate__fadeOut: prev === 0,
              })}
              src={
                Config.environment
                  ? "/images/about.png"
                  : Config.assetUrl + "home-about-1.png"
              }
              alt="best website design services"
            />
          </LazyLoad>
          <LazyLoad classNamePrefix="abcimg">
            <img
              className={classnames("abcimg animate__animated", {
                active: active === 1 || prev === 1,
                animate__fadeIn: active === 1,
                animate__fadeOut: prev === 1,
              })}
              src={
                Config.environment
                  ? "/images/home2 (1).png"
                  : Config.assetUrl + "home-about-2.png"
              }
              alt="website branding and design service"
            />
          </LazyLoad>
          <LazyLoad classNamePrefix="abcimg">
            <img
              className={classnames("abcimg animate__animated", {
                active: active === 2 || prev === 2,
                animate__fadeIn: active === 2,
                animate__fadeOut: prev === 2,
              })}
              src={
                Config.environment
                  ? "/images/home2 (2).png"
                  : Config.assetUrl + "home-about-3.png"
              }
              alt="android app development and design service"
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}

export default About;
