import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        } else if (this.props.withQuery && this.props.location.search !== prevProps.location.search) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return <React.Fragment />
    }
}

export default withRouter(ScrollToTop)