import React, { useEffect, useState, Fragment } from "react";
import "./header.scss";
import { Nav } from "reactstrap";
import { useHistory, Link, NavLink } from "react-router-dom";
import classnames from "classnames";
import { IoRocketOutline } from "react-icons/io5";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { ClassNames } from "@emotion/react";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const logoImage = require("../../../assets/icons/white.svg").default;

  return (
    <Fragment>
      <div className="x-home-header-block fixed-top">
        <div
          className={classnames(
            "x-header",
            { active: navbar === true },
            { "x-closed": !isOpen,"x-opened":isOpen }
          )}
        >
          <div className="x-topnav-wrapper">
            <Link to="/">
              <img
                src={logoImage}
                alt="a2z web solution"
                className="x-logo"
                onClick={() => setIsOpen(false)}
              />
            </Link>
            {isOpen ? (
              <AiOutlineClose
                color="white"
                className="x-menu-close-icon"
                onClick={toggle}
              />
            ) : (
              <FiMenu color="white" className="x-menu-icon" onClick={toggle} />
            )}
          </div>
          <Nav>
            <NavLink
              to="/"
              activeClassName="x-active"
              className="nav-link animation"
              exact
              onClick={() => setIsOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              activeClassName="x-active"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              About
            </NavLink>
            <NavLink
              to="/services"
              activeClassName="x-active"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Services
            </NavLink>
            <NavLink
              to="/casestudies"
              activeClassName="x-active"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Case Studies
            </NavLink>
            <NavLink
              to="/blogs"
              activeClassName="x-active"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Blogs
            </NavLink>
            <NavLink
              to={"/contact"}
              activeClassName="x-active"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Contact
            </NavLink>
          </Nav>
          <Link className="mr-lg-4 search" to={"/pricing"}>
            <div className="bttnnn" onClick={() => setIsOpen(false)}>
              <IoRocketOutline className="icnh" />
              <div className="txt x-hover">Start Project</div>
            </div>
          </Link>
        </div>
        <div className="x-header-spacing" />
      </div>
    </Fragment>
  );
}
