import React, { Fragment, useState } from "react";
import { Carousel, CarouselItem } from "reactstrap";
import { BsCircleFill } from "react-icons/bs";
import "./process.scss";
import classnames from "classnames";
import LazyLoad from "react-lazyload";
import Config from "../../../config";

const items = [
  {
    src: Config.environment
      ? "/images/dicovery.jpg"
      : Config.assetUrl + "home-process-1.jpg",
    text: "Our design process begins with a friendly discussion. During this talk, we will discuss what exactly you are looking for in terms of color schemes, form factors, personality, font, etc.",
  },
  {
    src: Config.environment
      ? "/images/planning.jpg"
      : Config.assetUrl + "home-process-2.jpg",
    text: "Once we have a get the direction, we will dive deeper into the details of how the final performance will pan out. This involves massive brainstorming sessions, critically discussing the possible concepts, & clearing up any questions you may have.",
  },
  {
    src: Config.environment
      ? "/images/design.png"
      : Config.assetUrl + "home-process-3.png",
    text: "We provide a prototype to our customer to get a look and feel how the project will be post development. A2Z Web Solution design prototypes which are compatible on cross-platform which facilitate clients to check their project UI/UX (User Interface / User Experience). And finalize once that is confirmed by our customer.",
  },
  {
    src: Config.environment
      ? "/images/development.jpg"
      : Config.assetUrl + "home-process-4.jpg",
    text: "Here we look after required front-end, back-end, API’s, web services in a strategical way to develop an optimized well-designed code using cutting edge technologies. Product designed should be scalable, multi-tenancy, third party integrable but still all should be as per client’s requirement is something we focus on. For which we always value our clients review during the development phase.",
  },
  {
    src: Config.environment
      ? "/images/testing.jpg"
      : Config.assetUrl + "home-process-5.jpg",
    text: "We test each modules of project manually and when required in an automated way. Report bugs post testing and same is again fixed and re-tested until a clean quality of product is developed. Product development is tested and validated as a final approval from client. Where client check all the functionality of the product is working as expected.",
  },
];

const Process = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="p-cont-img">
          <LazyLoad>
            <img className="proc-img" src={items[activeIndex].src} />
          </LazyLoad>
          <div className="proc-txt">{items[activeIndex].text}</div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <Fragment>
      <div className="x-p-cont">
        <div className="container-fluid p-cont ">
          <h3 class="text-white headng p-cont-head">Our Process</h3>
          <div className="d-flex justify-content-center">
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              {slides}
            </Carousel>
          </div>
        </div>
        <div className="container-fluid d-flex justify-content-center p-proc-cont">
          <div className="d-flex justify-content-center row justify-content-between x-paths">
            <div>
              <img
                className="arrow-img arrow-img-left"
                onClick={() => previous()}
                src={require("../../../assets/icons/Vector.svg").default}
              />
            </div>
            <div>
              <div
                style={{ cursor: "pointer" }}
                className={classnames("proces", { active: activeIndex >= 0 })}
                onClick={() => setActiveIndex(0)}
              >
                Discovery
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classnames("logo", { active: activeIndex >= 0 })}
                >
                  <BsCircleFill
                    className="logo-icon"
                    onClick={() => setActiveIndex(0)}
                  />
                </div>
              </div>
            </div>
            <div
              className={classnames("line", { active: activeIndex >= 1 })}
            ></div>
            <div>
              <div
                className={classnames("proces", { active: activeIndex >= 1 })}
                onClick={() => setActiveIndex(1)}
                style={{ cursor: "pointer" }}
              >
                Planning
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classnames("logo", { active: activeIndex >= 1 })}
                >
                  <BsCircleFill
                    className="logo-icon"
                    onClick={() => setActiveIndex(1)}
                  />
                </div>
              </div>
            </div>
            <div
              className={classnames("line", { active: activeIndex >= 2 })}
            ></div>
            <div>
              <div
                className={classnames("proces", { active: activeIndex >= 2 })}
                onClick={() => setActiveIndex(2)}
                style={{ cursor: "pointer" }}
              >
                Design
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classnames("logo", { active: activeIndex >= 2 })}
                >
                  <BsCircleFill
                    className="logo-icon"
                    onClick={() => setActiveIndex(2)}
                  />
                </div>
              </div>
            </div>
            <div
              className={classnames("line", { active: activeIndex >= 3 })}
            ></div>
            <div>
              <div
                className={classnames("proces", { active: activeIndex >= 3 })}
                onClick={() => setActiveIndex(3)}
                style={{ cursor: "pointer" }}
              >
                Development
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classnames("logo", { active: activeIndex >= 3 })}
                >
                  <BsCircleFill
                    className="logo-icon"
                    onClick={() => setActiveIndex(3)}
                  />
                </div>
              </div>
            </div>
            <div
              className={classnames("line", { active: activeIndex >= 4 })}
            ></div>
            <div>
              <div
                className={classnames("proces", { active: activeIndex >= 4 })}
                onClick={() => setActiveIndex(4)}
                style={{ cursor: "pointer" }}
              >
                Testing
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classnames("logo", { active: activeIndex >= 4 })}
                >
                  <BsCircleFill
                    className="logo-icon"
                    onClick={() => setActiveIndex(4)}
                  />
                </div>
              </div>
            </div>
            <div>
              <img
                className="arrow-img arrow-img-right"
                onClick={() => next()}
                src={require("../../../assets/icons/Vector-1.svg").default}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Process;
