import React from "react";
import "./footer.scss";
import "../../fonts/inter/fonts.css";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";

function Footer() {
  let history = useHistory();

  function Home() {
    history.push("/");
  }

  function About() {
    history.push("/about");
  }
  function Case() {
    history.push("/portfolio");
  }
  function Blog() {
    history.push("/");
  }
  function Contact() {
    history.push("/contact");
  }

  function Home2() {
    history.push("/serviceWeb");
  }

  function About2() {
    history.push("/serviceDM");
  }
  function Case2() {
    history.push("/servicemobile");
  }
  function Blog2() {
    history.push("/servicebrand");
  }
  function Contact2() {
    history.push("/servicetesting");
  }

  function Home3() {
    history.push("/about");
  }

  return (
    <div className="x-footer">
      <div className="x-block">
        <div className="x-logo-container">
          <div>
            <div>
              <LazyLoad>
                <img
                  src={require("./black-logo.png").default}
                  alt="a2z web solution"
                  className="x-logo-image"
                />
              </LazyLoad>
            </div>
            <div className="x-icons-container">
              <a href="https://twitter.com/a2z_web">
                <img
                  src={require("./twitter.png").default}
                  alt="twitter"
                  className="x-social-icon"
                />
              </a>
              <a href="https://www.facebook.com/A2Z-Web-Solution-LLC-104494348335603">
                <img
                  src={require("./facebook.png").default}
                  alt="facebook"
                  className="x-social-icon"
                />
              </a>
              <a href="https://www.instagram.com/a2zwebsolution_/">
                <img
                  src={require("./Vector.png").default}
                  alt="insta"
                  className="x-social-icon"
                />
              </a>
              <a href="https://www.linkedin.com/company/75457342/admin/">
                <img
                  src={require("./linkedin.png").default}
                  alt="linkedin"
                  className="x-social-icon"
                />
              </a>
            </div>
          </div>
          <div className="x-copywrite">© 2021 A2Zwebsolution</div>
        </div>
        <div className="x-items-container">
          <div className="x-item-col">
            <div className="x-item-title">Links</div>
            <div className="x-item" onClick={Home}>
              Home
            </div>
            <div className="x-item" onClick={About}>
              About
            </div>
            <div className="x-item" onClick={Case}>
              Case Studies
            </div>
            <div className="x-item" onClick={Blog}>
              Blog
            </div>
            <div className="x-item" onClick={Contact}>
              Contact
            </div>
          </div>
          <div className="x-item-col">
            <div className="x-item-title">Services</div>
            <div className="x-item" onClick={Home2}>
              Web Design & Development
            </div>
            <div className="x-item" onClick={About2}>
              Digital Marketing
            </div>
            <div className="x-item" onClick={Case2}>
              Mobile App Development
            </div>
            <div className="x-item" onClick={Blog2}>
              Branding & Design
            </div>
            <div className="x-item" onClick={Contact2}>
              Testing & QA
            </div>
          </div>
          <div className="x-item-col">
            <div className="x-item-title">Company</div>
            <div className="x-item" onClick={Home3}>
              About
            </div>
            <a href='/file/terms.pdf' target='_blank' >
            <div className="x-item">Terms</div>
            </a>
            <a href='/file/privacy.pdf' target='_blank' >
            <div className="x-item">Privacy Policy</div>
            </a>
            <div className="x-item">Careers</div>
          </div>
          {/* <div className="x-item-col">
            <div className="x-item-title">More</div>
            <div className="x-item">Documentation</div>
            <div className="x-item">License</div>
            <div className="x-item">Changelog</div>
          </div> */}
        </div>
      </div>{" "}
      <div className="x-bottom-container">
      <div className="x-icons-container">
              <a href="https://twitter.com/a2z_web">
                <img
                  src={require("./twitter.png").default}
                  alt="twitter"
                  className="x-social-icon"
                />
              </a>
              <a href="https://www.facebook.com/A2Z-Web-Solution-LLC-104494348335603">
                <img
                  src={require("./facebook.png").default}
                  alt="facebook"
                  className="x-social-icon"
                />
              </a>
              <a href="https://www.instagram.com/a2zwebsolution_/">
                <img
                  src={require("./Vector.png").default}
                  alt="insta"
                  className="x-social-icon"
                />
              </a>
              <a href="https://www.linkedin.com/company/75457342/admin/">
                <img
                  src={require("./linkedin.png").default}
                  alt="linkedin"
                  className="x-social-icon"
                />
              </a>
            </div>
        <div className="x-separator" />
        <div className="x-bottom">
          <LazyLoad>
            <img
              src={require("./black-logo.png").default}
              alt="a2z web solution"
              className="x-logo-image"
            />
          </LazyLoad>

          <div className="x-copywrite">© 2021 A2Zwebsolution</div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
