import React, { useState } from "react";
import "./slider.scss";
import { Carousel, CarouselItem } from "reactstrap";
import Header from "./header";

const items = [
  {
    altText: <div>Shift your business fast with A2Zwebsolution.</div>,
    para: `A2Z Web Solution LLC is a company which focuses on creating world class projects. By primarily focusing on discovering client’s requirement and project goals what has to be attained. `,
  }
];

const Example = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="x-slider-cont">
          <div class="text-white x-slider-head">{item.altText}</div>
          <p class=" x-slider-para">{item.para}</p>
        </div>
      </CarouselItem>
    );
  });

  return (
    <div className="slider-container">
      <h1
        style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
      >
        A2Z Websolutions LLC - Best web development service in USA
      </h1>
      <Header />
      <div className="sliderimg ">
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

export default Example;
