import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Config from "../config";

class SiteSeo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: ""
        }

        this.fetchMetaData = this.fetchMetaData.bind(this);
    }

    componentDidMount() {
        this.fetchMetaData({ location: { pathname: "" } });
    }

    componentDidUpdate(prevProps) {
        this.fetchMetaData(prevProps);
    }

    fetchMetaData(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            var pathname = this.props.location.pathname;
            var url = undefined;
            var blogId = undefined;
            if (pathname.startsWith("/blogs/") && pathname.includes("-")) {
                blogId = pathname.split("-").pop();
            } else {
                url = pathname;
            }

            axios.get(`${Config.apiUrl}/meta-data?`,{params:{ url, blogId }})
                .then((response) => {
                    var data=response.data;
                    if (data?.results?.length > 0) {
                        const meta = data.results[0];
                        this.setState({ title: meta.title, description: meta.description });
                    } else {
                        this.setState({ title: "", description: "" });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                  });
        }
    }
    render() {
        return (
            <Helmet>
                <title>{this.state.title || "A2Z Web Solution LLC: web development and web designing"}</title>
                <meta name="description" content={this.state.description || "A2Z Web Solution LLC offers digital marketing services to help companies get to the next level. Our services cover SEO, SMM, UXUI, and Web Development."} />
            </Helmet>
        );
    }
}

export default withRouter(SiteSeo)