import React, { useState } from "react";
import "./portfolio.scss";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";
import Config from "../../../config";

function Portfolio() {
  let history = useHistory();

  function handleClick() {
    history.push("/casestudies/ecommerce-website");
  }
  function handleClick2() {
    history.push("/casestudies/health-app");
  }
  function handleClick3() {
    history.push("/casestudies/restaurant-app");
  }
  function handleClick4() {
    history.push("/casestudies/teachers-dashboard");
  }
  return (
    <div className="x-portfolio-container">
      <div className="container-fluid d-flex justify-content-center">
        <div className="container-fluid">
          <h3 className="port-head">Our Portfolio</h3>
          <div
            className="row d-flex justify-content-center"
            style={{ marginBottom: "6.5972vw" }}
          >
            <LazyLoad classNamePrefix="item">
              <div
                onClick={handleClick}
                className="card contn contn2"
                style={{ width: "40.416vw" }}
              >
                <img
                  className="portimg"
                  src={
                    Config.environment
                      ? "/images/App Mockups 35.png"
                      : Config.assetUrl + "home-port-1.png"
                  }
                  alt="ecommerce Ui Ux template for website"
                />
                <div className="card-body" style={{ padding: "2.777vw" }}>
                  <h4 className="card-title head2">E-commerce website</h4>
                  <p className="card-text field">UI UX design</p>
                  <p className="card-text field2">An Ecommerce Ui concept. </p>
                </div>
              </div>
            </LazyLoad>
            <LazyLoad classNamePrefix="item">
              <div
                onClick={handleClick2}
                className="card contn"
                style={{ width: "40.416vw" }}
              >
                <img
                  className="portimg"
                  src={
                    Config.environment
                      ? "/images/App Mockups 34.png"
                      : Config.assetUrl + "home-port-2.png"
                  }
                  alt="health app Ui Ux template for website"
                />
                <div className="card-body" style={{ padding: "2.777vw" }}>
                  <h4 className="card-title head2">Health App</h4>
                  <p className="card-text field">UI UX design</p>
                  <p className="card-text field2">
                    A fully functional app concept that help people locate and
                    choose doctors for consultations and prescriptions.
                  </p>
                </div>
              </div>
            </LazyLoad>
          </div>
          <div
            className="row d-flex justify-content-center"
            style={{ marginBottom: "8.680vw" }}
          >
            <LazyLoad classNamePrefix="item" className="x-left">
              <div
                onClick={handleClick3}
                className="card contn contn2"
                style={{ width: "40.416vw" }}
              >
                <img
                  className="portimg"
                  src={
                    Config.environment
                      ? "/images/App Mockups 33.png"
                      : Config.assetUrl + "home-port-3.png"
                  }
                  alt="restaurant Ui Ux template for website"
                />
                <div className="card-body" style={{ padding: "2.777vw" }}>
                  <h4 className="card-title head2">Resturant website</h4>
                  <p className="card-text field">UI UX design</p>
                  <p className="card-text field2">
                    An App for awesome delivery & dining experiences.
                  </p>
                </div>
              </div>
            </LazyLoad>
            <LazyLoad classNamePrefix="item">
              <div
                onClick={handleClick4}
                className="card contn contn2  no-mr"
                style={{ width: "40.416vw" }}
              >
                <img
                  className="portimg"
                  src={
                    Config.environment
                      ? "/images/teacher-dashboard-3.png"
                      : Config.assetUrl + "teacher-dashboard-3.png"
                  }
                  alt="teachers dashboard Ui Ux template for website"
                />
                <div className="card-body" style={{ padding: "2.777vw" }}>
                  <h4 className="card-title head2">Teacher Dashboard</h4>
                  <p className="card-text field">UI UX design</p>
                  <p className="card-text field2">
                    Teacher dashboard for school & Tutions.
                  </p>
                </div>
              </div>
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Portfolio;
