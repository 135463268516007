import React from "react";
import Slider from "../home/slider";
import About from "../home/about";
import Techcarousel from "../home/techcarousel";
import Process from "../home/process";
import Portfolio from "../home/portfolio";
import Clients from "../home/clientspeak";
import Footer from "../../layouts/footer";
import News from "../home/news";

function Index() {
  return (
    <div className="App">
      <Slider />
      <About />
      <Techcarousel />
      <Process />
      <Portfolio />
      <Clients />
      <News />
      <Footer />
    </div>
  );
}

export default Index;
