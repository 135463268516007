const Config = {
  get environment() {
    return "production";
  },
  get baseApiUrl() {
    return "https://a2zwebsolution-backend.caprover.a2zwebsolution.com";
    return "https://api.a2zwebsolution.com";
  },
  get apiUrl() {
    return Config.baseApiUrl + "/api/v1.0";
  },
  get assetUrl() {
    return (
      "https://a2zwebsolution.s3.amazonaws.com/web-assets/" ||
      "/images/"
    );
  },
};

export default Config;
